import React, { useState, useEffect } from 'react';
import { Form, Input, Rating, Button } from "semantic-ui-react"
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import stars from "../images/stars.png";

// function to create the sliders for the valence and energy
function CustomMarks({ chosed_color, chosed_min, chosed_max, type, onNewValue, characteristics, audience, energy, valence, onNewChange, base, onLoading }) {
    const MAX = 1;
    const MIN = -1;
    // const marks = [
    //     {
    //         value: 0,
    //         label: '',
    //     },
    //     {
    //         value: 0,
    //         label: '',
    //     },
    // ];

    const [val, setVal] = React.useState(base);
    const handleChange = (_, newValue) => {
        setVal(newValue);
        onNewValue(newValue)
    };

    return (
        <Box className='ui_slider_bar'>
            <Slider
                // marks={marks}
                value={val}
                step={0.01}
                valueLabelDisplay="auto"
                min={MIN}
                max={MAX}
                onChange={handleChange}
                color={chosed_color}
                size='large'
                style={{ paddingTop: 3 }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography
                    variant="body2"
                    onClick={() => setVal(MIN)}
                    sx={{ cursor: 'pointer' }}
                    component={'span'}
                >
                    <div className='ui_sliders_subnames'>{chosed_max}</div>
                </Typography>
                <Typography
                    variant="body2"
                    onClick={() => setVal(MAX)}
                    sx={{ cursor: 'pointer' }}
                    component={'span'}
                >
                    <div className='ui_sliders_subnames'>{chosed_min}</div>
                </Typography>
            </Box>
        </Box>
    );

}


export const LandingPage = ({ onNewChange, data, onLoading }) => {
    const [characteristics, setCharacteristics] = useState("")
    const [audience, setAudience] = useState("")
    const [valence, setValence] = useState(data[0].valence)
    const [energy, setEnergy] = useState(data[0].energy)

    return (
        <Form>
            <Form.Field className='landing_page_input'>
                <textarea className='ui_input'
                    placeholder={data[0].example_characteristics}
                    value={characteristics}
                    onChange={e =>
                        setCharacteristics(e.target.value)}
                >
                </textarea>
            </Form.Field>

            <Form.Field className='landing_page_input'>
                <textarea className='ui_input'
                    placeholder={data[0].example_audience}
                    value={audience}
                    onChange={e =>
                        setAudience(e.target.value)}
                >
                </textarea>
            </Form.Field>

            <Form.Field className='ui_slider'>
                <label> Define the Affect level </label>
                <CustomMarks
                    chosed_color={"primary"}
                    chosed_min={"Positive affect"}
                    chosed_max={"Negative affect"}
                    type="valence"
                    onNewValue={
                        value => {
                            setValence(currentValue => value)
                        }}
                    characteristics={characteristics}
                    audience={audience}
                    energy={energy}
                    valence={valence}
                    onNewChange={onNewChange}
                    base={valence}
                    onLoading={onLoading}

                />
            </Form.Field>

            <Form.Field className='ui_slider'>
                <label> Define the Energy level </label>
                <CustomMarks
                    chosed_color={"secondary"}
                    chosed_min={"High energy"}
                    chosed_max={"Low energy"}
                    type="energy"
                    onNewValue={
                        value => {
                            setEnergy(currentValue => value)
                        }}
                    characteristics={characteristics}
                    audience={audience}
                    energy={energy}
                    valence={valence}
                    onNewChange={onNewChange}
                    base={energy}
                    onLoading={onLoading}
                />
            </Form.Field>

            <Form.Field className='centered'>
                {/* Make a request on the server with updated info*/}
                <button className="ui_button" onClick={async () => {
                    onLoading(true)
                    const final = { "characteristics": characteristics, "audience": audience, "energy": energy, "valence": valence }
                    const response = await fetch('/add_data', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(final)
                    })

                    // check if the response worked
                    if (response.ok) {
                        console.log('response send !')
                        onNewChange(1)
                    }

                }}>
                    <img src={stars} className='space_icon' />
                    <div>
                        Generate your neuroaesthetic guidelines
                    </div>
                </button>
            </Form.Field>

        </Form>

    )
}