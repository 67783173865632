import React, { useState, useEffect } from 'react';
import mic from "../images/mic.png";
import play from "../images/play.png";
import { useNavigate } from 'react-router-dom';




export const ResponseDisplay = ({ data }) => {

    const navigate = useNavigate();

    //Retrieve the various data
    const guidelines_voices = data.at(-1).voice_guidelines.replaceAll("[", '').replaceAll("]", '').replaceAll("', '", '').replaceAll("'", "").split("*").filter(elm => elm).filter((word) => word.length > 6).slice(0, 3); //put the voice guideline as a list of guidelines, take only the first 3

    const emotion_1 = data.at(-1).emotion_1;
    const emotion_2 = data.at(-1).emotion_2;
    const emotion_3 = data.at(-1).emotion_3;

    const emotion_general_1 = data.at(-1).general_emotion_1;
    const emotion_general_2 = data.at(-1).general_emotion_2;
    const emotion_general_3 = data.at(-1).general_emotion_3;

    const job_id = data.at(-1).job_id;


    //Change the font color depending on the background color
    const white_fonts = ['Red', "Orange", "Black", "Bright blue", "Dark blue", "Gray", "Pink", "Blue", "Sky blue", "Chocolate", "Light green", "Dark yellow", "Light purple", "Green", "Jade", "Brown", "Dark pink", "Indigo"];
    const black_fonts = ['Yellow', "Bright pink", "Bright green", "White", "Light blue", "Aqua"];


    return (
        <div>
            <div className='voice_results'>
                <div className='title_results'>Voices</div>
                {guidelines_voices.map(
                    guideline => {
                        return (
                            <div className='voice_items' key={guideline}>
                                <img src={mic} className='mic_icon' />
                                <div className='voice_guidelines'>{guideline}</div>
                            </div>
                        )
                    }
                )}
            </div>
            <div className='music_results'>
                <div className='title_results'>Musics</div>
                <div className='voice_items'>
                    <img src={play} className='play_icon' />
                    <div className='voice_guidelines'>{data.at(-1).music1_artist.replaceAll("[", '').replaceAll("]", '')}  - {data.at(-1).music1_title.replaceAll("[", '').replaceAll("]", '')}</div>
                </div>
                <div className='voice_items'>
                    <img src={play} className='play_icon' />
                    <div className='voice_guidelines'>{data.at(-1).music2_artist.replaceAll("[", '').replaceAll("]", '')}  - {data.at(-1).music2_title.replaceAll("[", '').replaceAll("]", '')}</div>
                </div>
                <div className='voice_items'>
                    <img src={play} className='play_icon' />
                    <div className='voice_guidelines'>{data.at(-1).music3_artist.replaceAll("[", '').replaceAll("]", '')} - {data.at(-1).music3_title.replaceAll("[", '').replaceAll("]", '')}</div>
                </div>
            </div>

            <div className='emotions_results'>
                <div className='big_title_results emotions_title'>Evoked emotions</div>
                <ul className='cloud'>
                    <li className='emotion1'>{emotion_1}</li>
                    <li className='emotion2'>{emotion_general_1}</li>
                    <li className='emotion3'>{emotion_2}</li>
                    <li className='emotion4'>{emotion_general_2}</li>
                    <li className='emotion5'>{emotion_3}</li>
                    <li className='emotion6'>{emotion_general_3}</li>
                </ul>
            </div>

            <div className='big_title_results recommended_features'>Recommended features</div>

            <div className='color_results'>
                <div className='title_results'>Colors</div>
                <div className='color_item' style={{ backgroundColor: 'rgb' + data.at(-1).color_1_r, color: white_fonts.includes(data.at(-1).color_1) ? '#F2F2F2' : 'black' }}>
                    <div className='color_name'> {data.at(-1).color_1} </div>
                    <div className='color_rgb'> RGB {data.at(-1).color_1_r} </div>
                </div>
                <div className='color_item' style={{ backgroundColor: 'rgb' + data.at(-1).color_2_r, color: white_fonts.includes(data.at(-1).color_2) ? '#F2F2F2' : 'black' }}>
                    <div className='color_name'> {data.at(-1).color_2} </div>
                    <div className='color_rgb'> RGB {data.at(-1).color_2_r} </div>
                </div>
                <div className='color_item' style={{ backgroundColor: 'rgb' + data.at(-1).color_3_r, color: white_fonts.includes(data.at(-1).color_3) ? '#F2F2F2' : 'black' }}>
                    <div className='color_name'> {data.at(-1).color_3} </div>
                    <div className='color_rgb'> RGB {data.at(-1).color_3_r} </div>
                </div>

            </div>

            <div className='tagline_results'>
                <div className='title_results'>Font</div>
                <div className={data.at(-1).best_typo_1.replaceAll("(", '').replaceAll(")", '').replaceAll(",", '').replaceAll("'", "") + " tagline_items"}>
                    <div className='tagline'>{data.at(-1).tagline.replaceAll('"', '')}</div>
                    <div className='font_name'>{data.at(-1).best_typo_1.replaceAll("(", '').replaceAll(")", '').replaceAll(",", '').replaceAll("'", '')}</div>
                </div>

                <div className={data.at(-1).best_typo_2.replaceAll("(", '').replaceAll(")", '').replaceAll(",", '').replaceAll("'", "") + " tagline_items"}>
                    <div className='tagline'>{data.at(-1).tagline.replaceAll('"', '')}</div>
                    <div className='font_name'>{data.at(-1).best_typo_2.replaceAll("(", '').replaceAll(")", '').replaceAll(",", '').replaceAll("'", '')}</div>
                </div>

                <div className={data.at(-1).best_typo_3.replaceAll("(", '').replaceAll(")", '').replaceAll(",", '').replaceAll("'", "") + " tagline_items"}>
                    <div className='tagline'>{data.at(-1).tagline.replaceAll('"', '')}</div>
                    <div className='font_name'>{data.at(-1).best_typo_3.replaceAll("(", '').replaceAll(")", '').replaceAll(",", '').replaceAll("'", '')}</div>
                </div>
            </div>

            <button className='generate_moodboard' onClick={() => navigate('/moodboard_example', { state: job_id })}> Generate moodboard </button>

            <button className='resources_button' onClick={() => navigate('/ressources_page', { state: { url: "/result_example", data: job_id } })}> Sources </button>
        </div>

    )
}