import React, { useState, useEffect } from 'react';
import './App.css';
import './Fonts.css';
import { LandingPage } from "./components/landingPage"
import img_btn_ex from "./images/arrow_circle_up.png";
import gif_loading from "./images/loadingGif5.gif";
import { useNavigate } from 'react-router-dom';

const apiUrl = process.env.REACT_API_URL;
const appUrl = process.env.REACT_APP_URL;


function App() {

  //We can personalize this to have a first exemple showing on the screen
  const [database, setDatabase] = useState([{
    "valence": 0,
    "energy": 0,
    "characteristics": "",
    "audience": "",
    "example_audience": "Do you have a specific target audience in mind?",
    "example_characteristics": "What do you want to do?",
    'music1_artist': "",
    'music1_title': "",
    'music2_artist': "",
    'music2_title': "",
    'music3_artist': "",
    'music3_title': "",
    'emotion_1': '',
    'emotion_2': '',
    'emotion_3': '',
    'general_emotion_1': '',
    'general_emotion_2': '',
    'general_emotion_3': '',
    'color_1': '',
    'color_1_r': 0,
    'color_2': '',
    'color_2_r': 0,
    'color_3': '',
    'color_3_r': 0,
    'tagline': '',
    'attribute_typo': '',
    'best_typo_1': '',
    'best_typo_2': '',
    'best_typo_3': '',
    'voice_guidelines': '',
    'tag_guidelines': '',
    'liste_guidelines': '',
    'proposed_prompt_1': "",
    'link_image_1_1': "",
    'link_image_1_2': "",
    'link_image_1_3': "",
    'link_image_1_4': "",
    'proposed_prompt_2': "",
    'link_image_2_1': "",
    'link_image_2_2': "",
    'link_image_2_3': "",
    'link_image_2_4': "",
    'proposed_prompt_3': "",
    'link_image_3_1': "",
    'link_image_3_2': "",
    'link_image_3_3': "",
    'link_image_3_4': "",
    'proposed_prompt_4': "",
    'link_image_4_1': "",
    'link_image_4_2': "",
    'link_image_4_3': "",
    'link_image_4_4': "",
    'proposed_prompt_5': "",
    'link_image_5_1': "",
    'link_image_5_2': "",
    'link_image_5_3': "",
    'link_image_5_4': "",
    "job_id": "",
  }]);

  const [change, setChange] = useState(0); //to know when to fetch the data
  const [isLoading, setIsLoading] = useState(false); //to show a loading screen

  //Only log when we first change something
  useEffect(() => {
    fetch(`/data`).then(response => response.json().
      then(data => {
        if (change != 0) {
          setDatabase(data.moodboard);
          window.location.href = "/results";//go to the result page when loaded
        };

      }))
  }, [change]); //to only start updating when changing the data (not take the last data)

  const navigate = useNavigate();

  //Go to the result page when data send
  const handleClickResults = (data) => {
    navigate('/result_example', { state: { data } });
  };



  return (
    <>
      {/* Show the loading screen or the input fields */}
      {isLoading ?
        <div className="loader">
          <div className="loaderText">
            <div>Generating your custom</div>
            <div>Neuroaesthetic Guideline Sheet.</div>
            <div>Please wait...</div>
          </div>
          <img src={gif_loading} alt="loader" className="loaderGif" />
        </div>
        :
        <div className='landpage_blocks'>
          {/* The main input field */}
          <LandingPage
            onNewChange={change => setChange(currentChanges => change + currentChanges)}
            data={database}
            onLoading={isLoading => setIsLoading(currentLoad => isLoading)}
          />
          {/* The different examples */}
          <div className='examples'>
            <div className='example_title'>Get inspired</div>
            <div className='example_container'>
              <button className='example_button' onClick={async () => {
                // setIsLoading(true)
                const final = { "example_number": "example_1" }
                const response = await fetch(`/premade_example`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(final)
                })

                // check if the response worked
                if (response.ok) {
                  console.log('Example 1 send !')
                  handleClickResults("example_1")
                }

              }}>
                <div className='example_description'>I want to create an angry impactful ad that makes young people think about climate change</div>
                <img src={img_btn_ex} className='img_button_example' />
              </button>
              <button className='example_button' onClick={async () => {
                // setIsLoading(true)
                const final = { "example_number": "example_2" }
                const response = await fetch(`/premade_example`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(final)
                })

                // check if the response worked
                if (response.ok) {
                  // setIsLoading(false)
                  console.log('response example 2 send !')
                  handleClickResults("example_2")
                }

              }}>
                <div className='example_description'>I want to advertise an energetic black soap bottle targetted to Japanse food lovers</div>
                <img src={img_btn_ex} className='img_button_example' />
              </button>
              <button className='example_button' onClick={async () => {
                // setIsLoading(true)
                const final = { "example_number": "example_3" }
                const response = await fetch(`/premade_example`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(final)
                })

                // check if the response worked
                if (response.ok) {
                  // setIsLoading(false)
                  console.log('response example 3 send !')
                  handleClickResults("example_3")
                }

              }}>
                <div className='example_description'>I want to promote a calming lavender candle for Arabic self-care lovers</div>
                <img src={img_btn_ex} className='img_button_example' />
              </button>
              <button className='example_button' onClick={async () => {
                // setIsLoading(true)
                const final = { "example_number": "example_4" }
                const response = await fetch(`/premade_example`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(final)
                })

                // check if the response worked
                if (response.ok) {
                  // setIsLoading(false)
                  console.log('response example 4 send !')
                  handleClickResults("example_4")
                }

              }}>
                <div className='example_description'>I want to create a luxury travel experience for confident professionals</div>
                <img src={img_btn_ex} className='img_button_example' />
              </button>
              <button className='example_button' onClick={async () => {
                // setIsLoading(true)
                const final = { "example_number": "example_5" }
                const response = await fetch(`/premade_example`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(final)
                })

                // check if the response worked
                if (response.ok) {
                  // setIsLoading(false)
                  console.log('response example 5 send !')
                  handleClickResults("example_5")
                }

              }}>
                <div className='example_description'>I want to advertise a pain relief cream for joint and muscle pain, for Athlete</div>
                <img src={img_btn_ex} className='img_button_example' />
              </button>
              <button className='example_button' onClick={async () => {
                // setIsLoading(true)
                const final = { "example_number": "example_6" }
                const response = await fetch(`/premade_example`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(final)
                })

                // check if the response worked
                if (response.ok) {
                  // setIsLoading(false)
                  console.log('response example 6 send !')
                  handleClickResults("example_6")
                }

              }}>
                <div className='example_description'>I want to promote a modern, electric car for tech-savvy </div>
                <img src={img_btn_ex} className='img_button_example' />
              </button>
              <button className='example_button' onClick={async () => {
                // setIsLoading(true)
                const final = { "example_number": "example_7" }
                const response = await fetch(`/premade_example`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(final)
                })

                // check if the response worked
                if (response.ok) {
                  // setIsLoading(false)
                  console.log('response example 7 send !')
                  handleClickResults("example_7")
                }

              }}>
                <div className='example_description'>I want to design a beachfront luxury resort for people seeking luxury and wellness-focused vacations </div>
                <img src={img_btn_ex} className='img_button_example' />
              </button>
              <button className='example_button' onClick={async () => {
                // setIsLoading(true)
                const final = { "example_number": "example_8" }
                const response = await fetch(`/premade_example`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(final)
                })

                // check if the response worked
                if (response.ok) {
                  // setIsLoading(false)
                  console.log('response example 8 send !')
                  handleClickResults("example_8")
                }

              }}>
                <div className='example_description'>I want to advocate an empowering, long-lasting, hydrating lipstick for career-oriented individuals </div>
                <img src={img_btn_ex} className='img_button_example' />
              </button>
            </div>
          </div>
          {/* The source button with the research papers */}
          <button className='resources_button' onClick={() => navigate('/ressources_page', { state: { url: "/", data: "" } })}> Sources </button>
        </div>
      }
    </>
  )


}

export default App;
