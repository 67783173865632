import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ResultPage from "./ResultsPage";
import Moodboard from "./Moodboard";
import ResultPageExample from "./ResultsPage_example";
import reportWebVitals from './reportWebVitals';
import MoodboardExample from './Moodboard_example';
import RessourcesPage from './RessourcesPage';

//react router
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";

//Different routes for the application
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/results",
    element: <ResultPage />,
  },
  {
    path: "/result_example",
    element: <ResultPageExample />,
  },
  {
    path: "/moodboard",
    element: <Moodboard />,
  },
  {
    path: "/moodboard_example",
    element: <MoodboardExample />,
  },
  {
    path: "/ressources_page",
    element: <RessourcesPage />,
  }
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <App/>
  <RouterProvider router={router} />

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
