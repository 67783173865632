import React, { useState, useEffect } from 'react';
import './App.css';
import './Fonts.css';
import './ressource_page.css';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import sources from './sources.json';


function RessourcesPage() {

  // Take back the sender URL and the number of example if it's a premade example
  const location = useLocation();

  const exampleData = location.state || {};
  const navigate = useNavigate();


  return (
    <div className='ressource_page_bloc'>
      <div className='sources_title'>Scientific resources</div>
      <div className='source_subtitle'>Here are the different sources from which those guidelines are built: </div>
      <ul className='source_block'>
        {sources.map(
          source => {
            return (
              <li key={source} className='source_item'>{source}</li>
            )
          }
        )}
      </ul>
      <button className='return_sources' onClick={() => navigate(exampleData.url, { state: { 'data': exampleData.data } })}> Return</button>
    </div>
  );
}

export default RessourcesPage;