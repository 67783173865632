import React, { useState, useEffect } from 'react';
import './App.css';
import './Fonts.css';
import gif_loading from "./images/loadingGif5.gif";
import { useLocation } from 'react-router-dom';
import { QuestionsForm } from "./components/questionForm_example";
import { ResponseDisplay } from "./components/responseDisplay_exemple";

function ResultPageExample() {
  const location = useLocation();
  const [database, setDatabase] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [change, setChange] = useState(0);


  // Take back the data send from the other page
  const exampleData = location.state || {};

  const fetchData = async (data) => {
    setIsLoading(true);
    try {
      const response = await fetch('/display_premade_example', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ example_number: data }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();

      setDatabase(responseData.result);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (exampleData) {
      fetchData(exampleData.data);
    }
  }, [exampleData]);


  return (
    <div>
      {/* Display either the loading page or the interactive display */}
      {isLoading ? (
        <div className="loader">
          <div className="loaderText">
            <div>Generating your custom</div>
            <div>Moodboard images.</div>
            <div>Please wait...</div>
          </div>
          <img src={gif_loading} alt="loader" className="loaderGif" />
        </div>
      ) : (
        <div className="results_page_blocks">
          {/* Charge data each time we change something */}
          {/* <QuestionsForm onNewChange={change => setChange(currentChanges => change+currentChanges)}/>  */}
          <div>
            <QuestionsForm
              onNewChange={change => setChange(currentChanges => change + currentChanges)}
              onLoading={isLoading => setIsLoading(currentLoad => isLoading)}
              data={database}
            />

            <ResponseDisplay data={database} />
            <button className='return_moodboard' onClick={() => window.location.href = "/"}> Return to homepage </button>
          </div>

        </div>
      )}
    </div>
  );
}

export default ResultPageExample;