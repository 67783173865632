import React, { useState, useEffect } from 'react';
import './App.css';
import './Fonts.css';
import gif_loading from "./images/loadingGif5.gif";

function Moodboard() {
  const [database, setDatabase] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const hasReturned = sessionStorage.getItem('hasReturned');
    if (hasReturned === 'true') {
      sessionStorage.removeItem('hasReturned');
      window.location.reload(true); // Force a hard refresh
    } else {
      fetchData('/image_generation');
    }
  }, []);

  const fetchData = async (endpoint) => {
    console.log(`Starting fetchData with endpoint: ${endpoint}`);
    setIsLoading(true);
    try {
      console.log(`Fetching endpoint: ${endpoint}`);
      await fetch(endpoint, {
        method: 'GET',
      });
      console.log(`Fetched endpoint: ${endpoint}`);

      console.log('Fetching data from /data');
      const response = await fetch('/data');
      const data = await response.json();
      console.log('Data fetched from /data:', data);

      console.log('Updating state with fetched data');
      setDatabase(data.moodboard);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
      console.log('Finished fetchData');
    }
  };

  const handleRegenerateImages = () => {
    console.log('Regenerating images');
    fetchData('/regenerate_image');
  };

  const handleReturnToMoodboard = () => {
    sessionStorage.setItem('hasReturned', 'true');
    window.location.href = "/results";
  };

  return (
    <div>
      {isLoading ? (
        <div className="loader">
          <div className="loaderText">
            <div>Generating your custom</div>
            <div>Moodboard images.</div>
            <div>Please wait...</div>
          </div>
          <img src={gif_loading} alt="loader" className="loaderGif" />
        </div>
      ) : (
        database && database.length > 0 ? (
          <div>
            <ImageRender data={database[database.length - 1]} onReturn={handleReturnToMoodboard} />
            <button className="regenerate_images" onClick={handleRegenerateImages}>
              Regenerate images
            </button>
          </div>
        ) : (
          <div>No data available</div>
        )
      )}
    </div>
  );
}

function ImageRender({ data, onReturn }) {
  console.log('Rendering ImageRender component with data:', data);

  const [selectedImage, setSelectedImage] = useState(null);
  const [failedImages, setFailedImages] = useState(new Set());

  const handleImageClick = (image) => {
    console.log('Image clicked:', image);
    setSelectedImage(image);
  };

  const handleCloseClick = () => {
    console.log('Closing full screen image');
    setSelectedImage(null);
  };

  const getImageIndex = () => {
    const index = link_images.indexOf(selectedImage);
    console.log('Selected image index:', index);
    return index;
  };

  const handleImageError = (image) => {
    console.error(`Failed to load image: ${image}`);
    setFailedImages(prevFailedImages => new Set(prevFailedImages).add(image));
  };

  const job_id = data.job_id;
  console.log('Job ID:', job_id);

  const prompt1 = data.proposed_prompt_1;
  const prompt2 = data.proposed_prompt_2;
  const prompt3 = data.proposed_prompt_3;

  const link_images = [
    data.link_image_1_1, data.link_image_1_2, data.link_image_1_3, data.link_image_1_4,
    data.link_image_2_1, data.link_image_2_2, data.link_image_2_3, data.link_image_2_4,
    data.link_image_3_1, data.link_image_3_2, data.link_image_3_3, data.link_image_3_4
  ];

  console.log('Link images:', link_images);

  const randomizedImages = [
    data.link_image_1_1, data.link_image_2_1, data.link_image_3_1,
    data.link_image_1_2, data.link_image_2_2, data.link_image_3_2,
    data.link_image_1_3, data.link_image_2_3, data.link_image_3_3,
    data.link_image_1_4, data.link_image_2_4, data.link_image_3_4
  ];

  console.log('Randomized images:', randomizedImages);

  const descriptions = [
    prompt1,
    prompt2,
    prompt3,
  ];

  console.log('Descriptions:', descriptions);

  const getDescription = (index) => {
    console.log('Getting description for index:', index);
    if (index < 4) return descriptions[0];
    if (index < 8) return descriptions[1];
    return descriptions[2];
  };

  // Updated getImagePath function to load images from public directory
  const getImagePath = (image) => {
    if (failedImages.has(image)) {
      return ''; // Skip rendering the image if it failed to load
    }
    return `/images/final_image/${image}`;
  };

  return (
    <div>
      <div className={`display_images ${selectedImage ? 'hidden' : ''}`}>
        {randomizedImages.map(image => (
          getImagePath(image) && ( // Only render if image path exists
            <img
              src={`${getImagePath(image)}`}
              className='image_items'
              key={image}
              onClick={() => handleImageClick(image)}
              alt="image"
              onError={() => handleImageError(image)}
            />
          )
        ))}

        <button className='return_moodboard' onClick={onReturn}>Return to moodboard</button>
      </div>

      {selectedImage && (
        <div className='fullscreen_overlay' onClick={() => setSelectedImage(null)}>
          <img
            src={`${getImagePath(selectedImage)}`}
            className='fullscreen_image'
            alt="fullscreen"
            onError={() => handleImageError(selectedImage)}
          />
          <button className='close_button' onClick={handleCloseClick}>&times;</button>
          <div className='image_description'>
            {getDescription(getImageIndex())}
          </div>
        </div>
      )}
    </div>
  );
}

export default Moodboard;
