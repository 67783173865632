import React, { useState, useEffect } from 'react';
import { Form, Input, Rating, Button } from "semantic-ui-react"
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';

//Function for the sliders for the valence and energy
function CustomMarks({ data, chosed_color, chosed_min, chosed_max, type, onNewValue, characteristics, audience, energy, valence, onNewChange, base, onLoading }) {

    const MAX = 1;
    const MIN = -1;
    // const marks = [
    //     {
    //         value: { chosed_min },
    //         label: '',
    //     },
    //     {
    //         value: { chosed_max },
    //         label: '',
    //     },
    // ];

    const [val, setVal] = useState(base);
    const handleChange = (_, newValue) => {
        setVal(newValue);
        onNewValue(newValue)
        //   sendChange(newValue, type)
    };

    return (
        <Box className='ui_slider_bar'>
            <Slider sx={{ color: 'gray' }}
                // marks={marks}
                value={val}
                step={0.01}
                valueLabelDisplay="auto"
                min={MIN}
                max={MAX}
                onChange={handleChange}
            //   color = {chosed_color}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography
                    variant="body2"
                    onClick={() => setVal(MIN)}
                    sx={{ cursor: 'pointer' }}
                    component={'span'}

                >
                    <div className='ui_sliders_subnames'>{chosed_max}</div>
                </Typography>
                <Typography
                    variant="body2"
                    onClick={() => setVal(MAX)}
                    sx={{ cursor: 'pointer' }}
                    component={'span'}

                >
                    <div className='ui_sliders_subnames'>{chosed_min}</div>
                </Typography>
            </Box>
        </Box>
    );

}


export const QuestionsForm = ({ onNewChange, data, onLoading }) => {

    const [characteristics, setCharacteristics] = useState(data.at(-1).characteristics)
    const [audience, setAudience] = useState(data.at(-1).audience)
    const [valence, setValence] = useState(data.at(-1).valence);
    const [energy, setEnergy] = useState(data.at(-1).energy);

    return (
        <Form className="result_questions">
            <Form.Field className='landing_page_results'>
                <label className='text_results_questions'> Objective </label>
                <textarea className='ui_input_results'
                    placeholder={characteristics}
                    value={characteristics}
                    onChange={e =>
                        setCharacteristics(e.target.value)}>
                </textarea>

            </Form.Field>

            <Form.Field className='landing_page_results'>
                <label className='text_results_questions'> Target </label>
                <textarea className='ui_input_results'
                    placeholder={audience}
                    value={audience}
                    onChange={e =>
                        setAudience(e.target.value)}>
                </textarea>
            </Form.Field>

            <Form.Field className='ui_slider'>
                <label className='text_results_questions'> Affect level </label>
                <CustomMarks
                    chosed_color={"primary"}
                    chosed_min={"positive affect"}
                    chosed_max={"negative affect"}
                    type="valence"
                    onNewValue={
                        value => {
                            setValence(currentValue => value)
                        }}
                    characteristics={characteristics}
                    audience={audience}
                    energy={energy}
                    valence={valence}
                    onNewChange={onNewChange}
                    // base = {valence}
                    base={valence}
                    onLoading={onLoading}
                    data={data}

                />
            </Form.Field>

            <Form.Field className='ui_slider'>
                <label className='text_results_questions'> Energy level </label>
                <CustomMarks
                    chosed_color={"secondary"}
                    chosed_min={"high energy"}
                    chosed_max={"low energy"}
                    type="energy"
                    onNewValue={
                        value => {
                            setEnergy(currentValue => value)
                        }}
                    characteristics={characteristics}
                    audience={audience}
                    energy={energy}
                    valence={valence}
                    onNewChange={onNewChange}
                    // base = {energy}
                    base={energy}
                    onLoading={onLoading}
                    data={data}
                />
            </Form.Field>

            <Form.Field className='centered'>
                {/* Make a request on the server with updated info*/}
                <button className="ui_button_results" onClick={async () => {
                    onLoading(true)
                    const final = { "characteristics": characteristics, "audience": audience, "energy": energy, "valence": valence }
                    const response = await fetch('/add_data', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(final)
                    })

                    // check if the response worked
                    if (response.ok) {
                        console.log('update response send !')
                        onNewChange(1)
                    }

                }}>
                    <div>
                        Re-generate
                    </div>
                </button>
            </Form.Field>


        </Form>

    )
}