// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./images/Background_ressources.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh; /* Use 100vh to cover the full viewport height */
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center center;
    background-size: cover;
    background-attachment: fixed; /* Keeps the background image fixed during scrolling */
}`, "",{"version":3,"sources":["webpack://./src/ressource_page.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,WAAW;IACX,aAAa,EAAE,gDAAgD;IAC/D,2EAA2E;IAC3E,sBAAsB;IACtB,4BAA4B,EAAE,sDAAsD;AACxF","sourcesContent":["body{\n    margin: 0;\n    padding: 0;\n    width: 100%;\n    height: 100vh; /* Use 100vh to cover the full viewport height */\n    background: url(./images/Background_ressources.png) no-repeat center center;\n    background-size: cover;\n    background-attachment: fixed; /* Keeps the background image fixed during scrolling */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
