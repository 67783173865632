import React, { useState, useEffect } from 'react';
import './App.css';
import './Fonts.css';
import gif_loading from "./images/loadingGif5.gif";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function MoodboardExample() {
  const [database, setDatabase] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  //Get the data from the webpage to know which example to display
  const exampleData = location.state || {};

  const fetchData = async (data) => {
    setIsLoading(true);
    try {
      const response = await fetch('/display_premade_images', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ example_number: data }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();

      setDatabase(responseData.result);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (exampleData) {
      fetchData(exampleData);
    }
  }, [exampleData]);


  const fetchDataRegen = async (data) => {
    setIsLoading(true);
    try {
      const response = await fetch('/changing_premade_examples', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ example_number: data }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();

    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      window.location.href = "/moodboard"
    }
  };

  const handleRegenerateImages = () => {
    fetchDataRegen(exampleData)

  };

  return (
    <div>
      {/* Change what to display depending if we are waiting a response or not */}
      {isLoading ? (
        <div className="loader">
          <div className="loaderText">
            <div>Generating your custom</div>
            <div>Moodboard images.</div>
            <div>Please wait...</div>
          </div>
          <img src={gif_loading} alt="loader" className="loaderGif" />
        </div>
      ) : (
        <div>
          <ImageRender data={database.at(-1)} />
          <button className="regenerate_images" onClick={handleRegenerateImages}>
            Regenerate images
          </button>
        </div>
      )}
    </div>
  );
}


function ImageRender({ data }) {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image); // Display the image in fullscreen
  };

  const handleCloseClick = () => {
    setSelectedImage(null); // Close the image in fullscreen
  };


  //Find the image index to get the correct prompt associated
  const getImageIndex = () => {
    return link_images.indexOf(selectedImage);
  };

  const navigate = useNavigate();

  const job_id = data.job_id;

  const prompt1 = data.proposed_prompt_1;
  const prompt2 = data.proposed_prompt_2;
  const prompt3 = data.proposed_prompt_3;
  // const prompt4 = data.proposed_prompt_4;
  // const prompt5 = data.proposed_prompt_5;

  const link_image_1_1 = data.link_image_1_1;
  const link_image_1_2 = data.link_image_1_2;
  const link_image_1_3 = data.link_image_1_3;
  const link_image_1_4 = data.link_image_1_4;

  const link_image_2_1 = data.link_image_2_1;
  const link_image_2_2 = data.link_image_2_2;
  const link_image_2_3 = data.link_image_2_3;
  const link_image_2_4 = data.link_image_2_4;

  const link_image_3_1 = data.link_image_3_1;
  const link_image_3_2 = data.link_image_3_2;
  const link_image_3_3 = data.link_image_3_3;
  const link_image_3_4 = data.link_image_3_4;

  const link_images = [link_image_1_1, link_image_1_2, link_image_1_3, link_image_1_4, link_image_2_1, link_image_2_2, link_image_2_3, link_image_2_4, link_image_3_1, link_image_3_2, link_image_3_3, link_image_3_4];

  // Change image display
  const randomizedImages = [link_image_1_1, link_image_2_1, link_image_3_1, link_image_1_2, link_image_2_2, link_image_3_2, link_image_1_3, link_image_2_3, link_image_3_3, link_image_1_4, link_image_2_4, link_image_3_4];

  const descriptions = [
    prompt1,
    prompt2,
    prompt3,
    // prompt4,
    // prompt5
  ];

  // Have the description depending on the image we click on
  const getDescription = (index) => {
    if (index < 4) return descriptions[0];
    if (index < 8) return descriptions[1];
    // if (index < 12) return descriptions[2];
    // if (index < 16) return descriptions[3];
    // return descriptions[4];
    return descriptions[2];
  };

  return (
    <div>
      {/* Grid display */}
      <div className={`display_images ${selectedImage ? 'hidden' : ''}`}>
        {randomizedImages.map(image => (
          <img
            src={require("./images/image_examples/" + image)}
            className='image_items'
            key={image}
            onClick={() => handleImageClick(image)}
            alt="image"
          />
        ))}

        {/* Return to the moodboard */}
        <button className='return_moodboard' onClick={() => navigate('/result_example', { state: { 'data': job_id } })}> Return to moodboard </button>

      </div>

      {/* When an image is selected, see it fullscreen */}
      {selectedImage && (
        <div className='fullscreen_overlay' onClick={() => setSelectedImage(null)}>
          <img
            src={require("./images/image_examples/" + selectedImage)}
            className='fullscreen_image'
            alt="fullscreen"
          />
          <button className='close_button' onClick={handleCloseClick}>&times;</button>
          <div className='image_description'>
            {getDescription(getImageIndex())}
          </div>
        </div>
      )}

    </div>
  );

}


export default MoodboardExample;