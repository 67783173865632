import React, { useState, useEffect } from 'react';
import './App.css';
import './Fonts.css';
import { QuestionsForm } from "./components/questionForm"
import { ResponseDisplay } from "./components/responseDisplay"
import { Container } from "semantic-ui-react"
import gif_loading from "./images/loadingGif5.gif";


function ResultPage() {

  const data_base = [{
    "valence": 0,
    "energy": 0,
    "characteristics": "",
    "audience": "",
    "example_audience": "Testing audience",
    "example_characteristics": "Testing characteristics",
    'music1_artist': "",
    'music1_title': "",
    'music2_artist': "",
    'music2_title': "",
    'music3_artist': "",
    'music3_title': "",
    'emotion_1': '',
    'emotion_2': '',
    'emotion_3': '',
    'general_emotion_1': '',
    'general_emotion_2': '',
    'general_emotion_3': '',
    'color_1': '',
    'color_1_r': 0,
    'color_2': '',
    'color_2_r': 0,
    'color_3': '',
    'color_3_r': 0,
    'tagline': '',
    'attribute_typo': '',
    'best_typo_1': '',
    'best_typo_2': '',
    'best_typo_3': '',
    'voice_guidelines': '',
    'tag_guidelines': '',
    'liste_guidelines': '',
    'proposed_prompt_1': "",
    'link_image_1_1': "",
    'link_image_1_2': "",
    'link_image_1_3': "",
    'link_image_1_4': "",
    'proposed_prompt_2': "",
    'link_image_2_1': "",
    'link_image_2_2': "",
    'link_image_2_3': "",
    'link_image_2_4': "",
    'proposed_prompt_3': "",
    'link_image_3_1': "",
    'link_image_3_2': "",
    'link_image_3_3': "",
    'link_image_3_4': "",
    'proposed_prompt_4': "",
    'link_image_4_1': "",
    'link_image_4_2': "",
    'link_image_4_3': "",
    'link_image_4_4': "",
    'proposed_prompt_5': "",
    'link_image_5_1': "",
    'link_image_5_2': "",
    'link_image_5_3': "",
    'link_image_5_4': "",
    "job_id": ""
  }];

  //We can personalize this to have a first exemple showing on the screen
  const [database, setDatabase] = useState(data_base);

  const [change, setChange] = useState(0);
  const [isLoading, setIsLoading] = useState(false); //to show a loading screen

  const [loadingOver, setLoadingOver] = useState(false); // to first display when loading is done

  // // get the data from the python server and display them, fetch each time the database change
  // useEffect( () => {
  //         fetch('/data').then(response => response.json().
  //         then(data=> {
  //           setDatabase(data.moodboard)}))
  // }, [change]);

  //Only log when we first change something
  //   useEffect( () => {
  //     fetch('/data').then(response => response.json().
  //     then(data=> { 
  //       if(change !=0){setDatabase(data.moodboard)}; //to only start updating when changing the data (not take the last data)
  //       setIsLoading(false);
  //       }))
  //   }, [change]);

  //Only log when we first change something
  useEffect(() => {
    fetch('/data').then(response => response.json().
      then(data => {
        setDatabase(data.moodboard); //to only start updating when changing the data (not take the last data)
        setIsLoading(false);
        setLoadingOver(true);
      }))
  }, [change]);



  return (
    <div>
      {/* Display either a loading or the interactive display */}
      {isLoading ?
        <div className="loader">
          <div className="loaderText">
            <div>Generating your custom</div>
            <div>Neuroaesthetic Guideline Sheet.</div>
            <div>Please wait...</div>
          </div>
          <img src={gif_loading} alt="loader" className="loaderGif" />
        </div>
        :
        <div className="results_page_blocks">
          {/* Charge data each time we change something */}
          {/* <QuestionsForm onNewChange={change => setChange(currentChanges => change+currentChanges)}/>  */}
          {loadingOver ?
            <div>
              <QuestionsForm
                onNewChange={change => setChange(currentChanges => change + currentChanges)}
                onLoading={isLoading => setIsLoading(currentLoad => isLoading)}
                data={database}
              />

              <ResponseDisplay data={database} />
              <button className='return_moodboard' onClick={() => window.location.href = "/"}> Return to homepage </button>
            </div> :
            <></>
          }
        </div>
      }
    </div>
  )


}

export default ResultPage;